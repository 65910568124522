<template>
  <nav
    class="flex justify-between h-20 border-b border-primary-gray-100 px-4 md:px-20 lg:px-28 py-4.5"
  >
    <div class="flex items-center">
      <div class="cursor-pointer flex items-center gap-1.5" @click="redirectToSignup">
        <img
          v-if="generalSettingLogoURL"
          :src="generalSettingLogoURL"
          class="w-8 h-8 rounded-full"
        />
        <div v-else>
          <icon class="w-8 h-8" icon="logoheader" />
        </div>
        <span class="text-primary-gray-800 font-inter text-lg leading-[33px] font-semibold">
          MyQampus
        </span>
      </div>
    </div>
    <div>
      <ul class="flex gap-3">
        <li v-if="isSignUpButtonVisible">
          <UIButton button="primary" class="signup-button" @click="redirectToSignup('signup')">
            Sign up
          </UIButton>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import icon from '@components/icons/icon.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import { redirectToSignup } from '@src/utils/generalUtil.js'
import { siteDomainHandler } from '@src/utils/site-utils.js'

export default {
  components: {
    UIButton,
    icon,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isSignUpButtonVisible: true,
      signUpPage: 'signUpPage',
    }
  },
  computed: {
    ...mapState('settings', ['generalSettingLogoURL']),
  },
  created() {
    this.appHeaderHandler()
  },
  methods: {
    redirectToSignup,
    appHeaderHandler() {
      this.isSignUpButtonVisible = siteDomainHandler().isDomainFieldVisible
    },
  },
}
</script>

<style scoped>
.signup-button {
  min-width: 95px;
  min-height: 44px;
}
</style>
