<template>
  <div class="bg-white min-h-screen">
    <div v-if="isLoading">
      <Loader :content="true" />
    </div>
    <div v-else>
      <AppHeader />
      <div class="flex justify-center sm:pt-24 pt-12 sm:pb-[92px] pb-14">
        <div class="w-[343px] sm:w-[360px]">
          <div class="mb-6 flex justify-center">
            <div class="text-center">
              <img
                v-if="generalSettingLogoURL"
                :src="generalSettingLogoURL"
                class="h-12 w-12 rounded-full"
              />
              <div v-else>
                <icon class="w-12 h-12" :icon="headerLogo" />
              </div>
            </div>
          </div>
          <div>
            <h1
              class="font-inter font-semibold text-primary-gray-900 text-2xl sm:text-3xl sm:leading-[38px] sm:mb-3 mb-2 text-center"
            >
              {{ title }}
            </h1>
            <p
              class="font-inter font-normal text-sm sm:text-base text-primary-gray-500 text-center"
            >
              {{ description }}
            </p>
            <div class="mt-8">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import generalMixin from '@src/mixins/general-mixins'
import { mapState } from 'vuex'
import AppHeader from '@src/router/layouts/feed/AppHeader.vue'
import icon from '@components/icons/icon.vue'
import Loader from '@components/BaseComponent/Loader.vue'

export default {
  components: {
    AppHeader,
    icon,
    Loader,
  },
  mixins: [generalMixin],
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    headerLogo: {
      type: String,
      default: 'logoheader',
    },
  },
  data() {
    return {
      signUpPage: 'signUpPage',
    }
  },
  computed: {
    ...mapState('settings', ['generalSettingLogoURL'], 'layout', ['isLoading']),
  },
}
</script>
