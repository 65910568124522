<template>
  <Auth
    title="Forgot Password?"
    description="To acquire a link to reset your password, please enter your email address"
    header-logo="forget-password"
  >
    <div>
      <ValidationObserver @submit="forgetPassword">
        <div class="mt-8">
          <InputFieldWrapper>
            <InputBox
              id="email"
              v-model.trim="userDetails.email"
              type="email"
              title="Email"
              name="Email"
              placeholder="Email"
              class="border-none"
              rules="required|email"
              @keyup.enter="forgetPassword"
            />
          </InputFieldWrapper>
          <div v-if="isDomainSelectionActive">
            <InputFieldWrapper>
              <InputBox
                id="text"
                v-model.trim="userDetails.sub_domain"
                type="text"
                placeholder="Institute Sub-Domain"
                class="border-none"
                title="SUB_DOMAIN"
                rules="required"
                @keyup.enter="forgetPassword"
              />
            </InputFieldWrapper>
          </div>
          <UIButton button="primary" :disabled="isLoading" class="w-full mt-1">
            <span v-if="isLoading">
              <Loader />
            </span>
            <span v-else>Continue</span>
          </UIButton>
          <div
            class="mt-8 font-semibold text-sm font-inter text-primary-gray-600 flex justify-center"
          >
            <router-link to="/signin">
              <span class="flex gap-2">
                <icon icon="arrowLeft" color="primary-gray-600" height="20" width="20" />
                Back to login
              </span>
            </router-link>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </Auth>
</template>

<script>
import Auth from '@layouts/auth.vue'
import icon from '@components/icons/icon.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapActions } from 'vuex'
import { Form as ValidationObserver } from 'vee-validate'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
import { siteDomainHandler } from '@src/utils/site-utils.js'
import UIButton from '@src/components/UiElements/UIButton.vue'

export default {
  components: {
    Auth,
    icon,
    InputBox,
    Loader,
    ValidationObserver,
    InputFieldWrapper,
    UIButton,
  },
  data() {
    return {
      userDetails: {
        email: '',
        sub_domain: '',
      },
      isDomainSelectionActive: true,
      isLoading: false,
    }
  },
  created() {
    this.domainHandler()
  },
  methods: {
    async domainHandler() {
      this.isDomainSelectionActive = siteDomainHandler().isDomainFieldVisible
      this.userDetails.sub_domain = siteDomainHandler().domain
    },
    forgetPassword() {
      this.isLoading = true
      this.getForgetPasswordPreInfo(this.userDetails)
        .then((response) => {
          this.$router?.push({
            name: 'choose-forget-option',
            query: {
              isSMSConfigured: response.is_sms_configured,
              maskedPhone: response.phone,
              maskedEmail: response.email,
              email: this.userDetails.email,
              subDomain: this.userDetails.sub_domain,
            },
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    ...mapActions('auth', ['getForgetPasswordPreInfo']),
  },
}
</script>

<style lang="scss" module>
@import '@design';
</style>

<style lang="scss">
@import '@src/router/views/auth/auth.scss';
</style>
